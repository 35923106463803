<template>
  <div class='personal-join'>
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('businessHandling')}} / {{$t('openClientAccount')}}</span></template>
    </el-page-header>

    <!-- form组件 -->
    <iaf :business="{businessCode:10,type:5}"></iaf>
  </div>
</template>
<script>
import iaf from '@/components/openAccount/individualAccountForm'
export default {
  components: { iaf },
}
</script>
<style  lang='less'>
.personal-join {
  background: #fff;
  .el-page-header {
    line-height: 40px;
    margin-left: 15px;
  }
  .my-card {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    .el-form-item .el-form-item__content .el-select {
      width: 100%;
    }
    .el-card__body {
      height: calc(100% - 30px);
    }
  }
}
</style>